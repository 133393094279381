@import 'styles/_variables.scss';

.Toastify {
  &__toast {
    background: #fff;
    border: 1px solid $main-theme;
    width: min-content;
    white-space: nowrap;
    text-align: right;

    &-container {
      display: flex;
      flex-direction: column;
      align-items: flex-end;
    }

    &-body {
      margin-right: 1em;
      color: #818a91;
      font-size: 0.9rem;
    }
  }

  &__close-button {
    color: $main-theme;

    &:focus {
      outline: none;
    }
  }
}
