@import 'styles/_variables.scss';

.loader {
  display: inline-block;
  position: relative;

  &Wrapper {
    position: fixed;
    top: 50%;
    left: 50%;
    width: auto;
    height: auto;
    background: transparent;
    transform: translate(-50%, -50%);
    z-index: 9999;

    &Hidden {
      opacity: 0;
      visibility: hidden;
      z-index: -9999;
    }
  }

  & div {
    box-sizing: border-box;
    display: block;
    position: absolute;
    width: 24px;
    height: 24px;
    margin: 8px;
    border: 4px solid #fff;
    border-radius: 50%;
    animation: lds-ring 1.2s cubic-bezier(0.5, 0, 0.5, 1) infinite;
    border-color: $main-theme transparent transparent transparent;

    &:nth-child(1) {
      animation-delay: -0.45s;
    }

    &:nth-child(2) {
      animation-delay: -0.3s;
    }

    &:nth-child(3) {
      animation-delay: -0.15s;
    }
  }
}

@keyframes lds-ring {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}
